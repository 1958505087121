<template>
  <v-row v-if="route != ''">
    <v-col cols="12">
      <v-card :color="cor.active.B" v-c:K>
        <v-overlay :opacity=".9" absolute :value="loading" :color="cor.active.b" :key="refresh">
          <div class="absolue top-0" style="width: 400px">
            <p class="text-center my0 py0">
              <img width="160px" class="rounded" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
            </p>
            <p class="text-center my0 py0">
              <v-progress-circular class="my0 py0" indeterminate size="36" v-c:P></v-progress-circular>
            </p>
            <p class="text-center my0 py0 fs-10pt" style="" v-c:K>
              carregando...
            </p>
          </div>
        </v-overlay>
        <div class="text-left pt-2 mb-0 pb-0">
          <div class="left ms-4 me-4 mt-4 fs-15pt f-raleway fw-500">Calendário</div>
          <div class="mx-4">
            <v-autocomplete class="my-0 pt-2 ms-4" dense solo v-model="selecionados" :items="itensBusca" chips color="blue-grey lighten-2" label="Buscar">
              <template v-slot:item="data">
                <div class="my-0 py-0" style="max-height: 10px;">
                  <i class="left" v-i:duo#calendar#16></i>
                  <p class="left fw-300 f-raleway fs-10pt ms-2 mt-1">
                    {{data.item}}
                  </p>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <div class="clear my-0 py-0"></div>
        <v-card-text class="mt-0 pt-0 text-left text-justify" v-c:K>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                HOJE
              </v-btn>
              <v-btn class="me-2" small fab text color="grey darken-2" @click="prev">
                <v-icon>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                <span v-if="type=='day'">
                  {{ diaMostrado }} de
                </span>
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-btn class="ms-2" small fab text color="grey darken-2" @click="next">
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small v-if="!listaMes" outlined class="mr-4" color="grey darken-2" @click="showListaMes">
                Formato de Lista
              </v-btn>
              <v-btn small v-if="listaMes" class="mr-4" :color="cor.active.S">
                Formato de Lista
              </v-btn>
              <v-btn small v-if="listaMes" outlined class="mr-4" color="grey darken-2" @click="listaMes = false; calendarStyle=''">
                Formato de grade
              </v-btn>
              <v-btn small v-if="!listaMes" class="mr-4" :color="cor.active.S" @click="listaMes = false; calendarStyle=''">
                Formato de grade
              </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-card height="800" :style="calendarStyle" :key="refresh">
            <v-calendar ref="calendar" v-model="value" :weekdays="weekday"
            :weekday-format="myDayFormat"
            :month-format="myMonthFormat"
            :type="type"
            :events="events"
            event-overlap-mode="stack"
            :event-overlap-threshold="30"
            :interval-count="1"
            @change="buildEvents"
            @click:more="clickMore"
            @click:event="clickEvento"
            >
          </v-calendar>
        </v-card>

        <v-card outlined class="p-2">
          <div class="" color="white" v-if="listaMes" style="">
            <div class="mt-3" v-if="Object.keys(eventosLista).length == 0">
              <v-alert type="info" :color="cor.active.S" v-c:K>
                <span class="fs-11pt">Nenhum evento encontrado</span>
              </v-alert>
            </div>
            <div class="" v-for="(lista,key,index) in eventosLista" :key="index">
              <div class="clear rounded p-2 mb-2 f-roboto fw-500 fs-14pt" v-bg:b v-c:K>
                {{lista.diaTexto}}
              </div>
              <itemevento :eventos="lista.eventosLista" />
            </div>
          </div>
        </v-card>

      </v-card-text>
    </v-card>
  </v-col>

  <v-tooltip v-if="tooltipEvento.evento != null" top :color="cor.active.b" v-model="tooltipEvento.visible" :position-x="tooltipEvento.posX" :position-y="tooltipEvento.posY">
    <div class="" style="max-width: 500px;">
      <p class="text-left my-1" :style="'color: '+tooltipEvento.evento.cor+'; fill: '+tooltipEvento.evento.cor">
        <i class="btn-icon-left" v-i:duo#record#26></i>
        <span class="fs-13pt f-lato fw-700 f-lato">{{tooltipEvento.evento.setor}}</span>
      </p >
      <hr class="my-1" :style="'color: '+cor.active.K">
      <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
        <span class="fw-700">Tema: </span>
        {{tooltipEvento.evento.tema}}
      </p>
      <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
        <span class="fw-700">Evento: </span>
        {{tooltipEvento.evento.descricao}}
      </p>
      <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
        <span class="fw-700">Início: </span>
        {{tooltipEvento.evento.dataInicioF}}
        <span class="fw-700 ms-2">Fim: </span>
        {{tooltipEvento.evento.dataFinalF}}
      </p>
      <p class="mt-1 mb-1 fs-10pt fw-400 f-lato" v-c:P>
        <span class="fw-700">Responsáveis: </span>
      </p>
      <div class="mt-0 pt-0">
        <div class="left mb-2 me-2 mt-0 pt-0" v-for="(user,key,index) in tooltipEvento.evento.pessoas" v-c:P :key="key">
          <v-avatar size="38">
            <img :src="user.urlFoto" alt="">
          </v-avatar>
          <span class="ms-1 fs-10pt f-lato fw-300">{{user.nome}}</span>
        </div>
      </div>
    </div>
  </v-tooltip>

  <v-tooltip top :color="cor.active.b" v-model="tooltip.visible" :position-x="tooltip.posX" :position-y="tooltip.posY">
    <div class="" style="">
      <div class="my-0 py-0" v-for="evento in eventosMore">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="fs-7pt f-lato"  :style="'text-transform: none; letter-spacing: normal; color: '+evento.cor+'; fill: '+evento.cor">
              <i class="btn-icon-left" v-i:duo#circle#20></i>
              <span class="fs-8pt fw-400 f-lato">{{evento.descricao}}</span>
            </v-btn>
          </template>
        </v-tooltip>
      </div>
    </div>
  </v-tooltip>

  <v-dialog v-model="dialog" max-width="480">
    <v-card>
      <v-card-title class="mb-1 pb-0 fw-500 f-lato fs-13pt">
        <span v-c:I>Eventos do dia:</span> <span class="ms-2 fs-14pt">{{dataClickMore}}</span>
      </v-card-title>
      <v-divider class="mt-0"></v-divider>
      <v-card-text>
        <div class="my-0 py-0" v-for="evento in eventosMore">
          <v-tooltip right color="white" open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-card v-bind="attrs" v-on="on" class="p-1 mb-1 fs-7pt f-lato text-truncate" :style="'max-width: 460px; text-transform: none; letter-spacing: normal; color: '+evento.cor+'; fill: '+evento.cor">
                <i class="btn-icon-left" v-i:duo#record#20></i>
                <span class="fs-8pt fw-400 f-lato">{{evento.descricao.substr(0,90)}}</span>
              </v-card>
            </template>
            <div class="" style="max-width: 500px;">
              <p class="text-left my-1" :style="'color: '+evento.cor+'; fill: '+evento.cor">
                <i class="btn-icon-left" v-i:duo#record#26></i>
                <span class="fs-13pt f-lato fw-700 f-lato">{{evento.setor}}</span>
              </p >
              <hr class="my-1" :style="'color: '+cor.active.K">
              <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
                <span class="fw-900 me-1">Tema: </span>
                <span class="fw-400 fs-11pt f-raleway">{{evento.tema}}</span>
              </p>
              <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
                <span class="fw-900 me-1">Evento: </span>
                <span class="fw-300 fs-11pt f-lato">{{evento.descricao}}</span>
              </p>
              <p class="my-0 fs-10pt fw-400 f-lato" v-c:P>
                <span class="fw-900 me-1">Início: </span>
                {{evento.dataInicioF}}
                <span class="fw-700 ms-2">Fim: </span>
                {{evento.dataFinalF}}
              </p>
              <p class="mt-1 mb-1 fs-10pt fw-400 f-lato" v-c:P>
                <span class="fw-900">Responsáveis: </span>
              </p>
              <div class="mt-0 pt-0">
                <div class="left mb-2 me-2 mt-0 pt-0" v-for="(user,index,key) in evento.pessoas" v-c:P :key="key">
                  <v-avatar size="38">
                    <img :src="user.urlFoto" alt="">
                  </v-avatar>
                  <span class="ms-1 fs-10pt f-lato fw-300">{{user.nome}}</span>
                </div>
              </div>
            </div>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-row>
</template>

<script>
import dummy from '@/components/dummy/dummy.vue'
import { faker } from '@/components/dummy/faker.js'
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js'
import moment from 'moment';
import { eventBus } from "@/main.js";
import itemevento from "./itemevento.vue";

export default {
  name: 'calendario',
  props: [ "route" ],
  components: { dummy, itemevento },
  computed: {
  },
  data () {
    return {
      selecionados: null,
      itensBusca: [],
      areaSel: { id: "todos", title: "Todas áreas", icon: "star", cor: "#cbbc00" },
      calendarStyle: "",
      listaMes: false,
      tooltip: { visible: false, posX: 0, posY: 0 },
      tooltipEvento: { visible: false, posX: 0, posY: 0, evento: null, activate: false },
      dialog: false,
      refreshKey: 0,
      loading: false,
      eventos: [],
      eventosMore: [],
      eventosLista: [],
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Days',
      },
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Dom - Sab', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Seg - Sex', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Seg - Sex', value: [1, 2, 3, 4, 5] },
        { text: 'Seg, Qua, Sex', value: [1, 3, 5] },
      ],
      diaMostrado: 0,
      mesMostrado: 0,
      dataClickMore: "",
      value: '',
      events: [],
      setores: {},
      refresh: 0,
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
    }
  },
  watch: {
    selecionados() {
      //console.log(this.selecionados);
      if(this.selecionados == null) {
        this.listaMes = false;
        this.calendarStyle = '';
      } else {
        this.showBusca();
      }
    }
  },
  created: function() {
    var self = this;
    this.log("Created");
  },
  mounted: function() {
    var self = this;

    //console.log("self.route",self.route);

    //self.areaSel = { id: "todos", title: "Todas áreas", icon: "star", cor: "#cbbc00" };
    eventBus.$emit("menuClick",self.areaSel);

    document.addEventListener("click", function(event) {
      //console.log("clientX: " + event.clientX + " - clientY: " + event.clientY);
      //console.log(self.tooltip.posX-event.clientX);
      //console.log(self.tooltip.posY-event.clientY);
      //if(!self.tooltip.visible && !self.tooltipEvento.visible) {
      self.tooltip.posX = event.clientX;
      self.tooltip.posY = event.clientY;
      self.tooltipEvento.posX = event.clientX;
      self.tooltipEvento.posY = event.clientY;
      //}
      self.tooltip.visible = false;
      self.tooltipEvento.visible = false;
    });

    window.moment = moment;
    this.log("Mounted");
    self.loading = true;
    rdb.ref('/calendarios/2022S1/setores').on('value', function(snapshot) {
      self.setores = snapshot.val();
      //console.log("self.setores",self.setores);
      self.buildEvents();
      rdb.ref('/calendarios/2022S1/eventos').on('value', function(snapshot) {
        var ret = snapshot.val();
        self.eventos = [];
        //console.log("self.route",self.route);
        for(var key in ret) {
          if(ret[key][self.route] != undefined) {
            for(var keySetor in ret[key].setores) {
              ret[key].setores[keySetor] = self.setores[keySetor];
              ret[key].cor = self.setores[keySetor].cor;
            }
            self.eventos.push(ret[key]);
          }
        }
        //self.eventos = ret;
        console.log("self.eventos",self.eventos);
        self.loading = false;
        self.buildEvents();
      });
    });

    eventBus.$on("menuClick", function(item) {
      self.areaSel = item;
      console.log("self.areaSel: ",self.areaSel);
      self.events = [];
      self.refresh++;
      self.$forceUpdate();
      if(self.listaMes) {
        self.buildEvents();
      }
    });

    //console.log("$vuetify.calendar.moreEvents",this.$refs.calendar);
    //window.calendar = this.$refs.calendar;

  },
  methods: {

    setToday () {
      this.value = ''
    },
    prev () {
      this.$refs.calendar.prev()
      //console.log("$vuetify.calendar",this.$refs.calendar);
    },
    next () {
      this.$refs.calendar.next()
    },

    myDayFormat(d) {
      //console.log(d.weekday);
      var days = [ "DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
      return days[d.weekday];
    },

    myMonthFormat(m) {
      //console.log(m);
      var months = [ "JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ" ];
      return months[m.month];
    },

    buildEvents(start, end) {
      var self = this;
      var events = [];
      self.itensBusca = [];
      //console.log("start",start);
      //console.log("end",end);
      if(start != undefined) {
        this.diaMostrado = start.start.day;
        this.mesMostrado = start.start.month;
        //console.log("this.diaMostrado",this.diaMostrado);
        //console.log("this.mesMostrado",this.mesMostrado);
      }
      //console.log("self.eventos",self.eventos);
      for(var key in self.eventos) {
        if(self.areaSel.id == "todos") {
          var cor = 'grey';
          if(self.eventos[key].setores != undefined) {
            cor = self.setores[Object.keys(self.eventos[key].setores)[0]].cor;
          }
          events.push({
            name: self.eventos[key].descricao,
            start: new Date(self.eventos[key].dataInicio),
            end: new Date(self.eventos[key].dataFinal),
            color: cor, //self.eventos[key].setores[Object.keys(self.eventos[key].setores)[0]].cor,
            timed: false,
            id: key,
          });
          self.itensBusca.push(self.eventos[key].descricao);
        } else {
          //console.log("self.areaSel.id",self.areaSel.id);
          if(self.eventos[key].setores != undefined) {
            if(self.eventos[key].setores[self.areaSel.id] != undefined) {
              events.push({
                name: self.eventos[key].descricao,
                start: new Date(self.eventos[key].dataInicio),
                end: new Date(self.eventos[key].dataFinal),
                color: self.setores[self.areaSel.id].cor, //self.eventos[key].setores[Object.keys(self.eventos[key].setores)[0]].cor,
                timed: false,
                id: key,
              });
              self.itensBusca.push(self.eventos[key].descricao);
            }
          }
        }
      }
      self.events = events;
      console.log("self.events",self.events);
      self.$forceUpdate();
      this.atualizaListaEventos();
    },

    clickMore(dados,ev) {
      var self = this;
      //console.log("dados",dados,ev);
      //console.log(self.eventos);
      var dia = ""+dados.day;
      var mes = ""+dados.month;
      if(dia.length < 2) dia = "0"+dia;
      if(mes.length < 2) mes = "0"+mes;
      var data = new Date(dados.year+"/"+mes+"/"+dia);
      self.dataClickMore = moment(data).format("DD/MM/YYYY");
      self.eventosMore = [];
      for(var idx in self.eventos) {
        var inicio = new Date(self.eventos[idx].dataInicio);
        var final = new Date(self.eventos[idx].dataFinal);
        if(data >= inicio && data <= final) {
          //console.log("ok: ",moment(data).format("DD/MM/YYYY"), moment(inicio).format("DD/MM/YYYY"),moment(final).format("DD/MM/YYYY"),self.eventos[idx].descricao);
          var evento = self.eventos[idx];
          evento.dataInicioF = moment(evento.dataInicio).format("DD/MM/YYYY");
          evento.dataFinalF = moment(evento.dataFinal).format("DD/MM/YYYY");
          var equipes = evento.setores;
          for(var key in equipes) {
            evento.pessoas = self.setores[key].pessoas;
          }
          self.eventosMore.push(evento);
        } else {
          //console.log("não: ",moment(data).format("DD/MM/YYYY"), moment(inicio).format("DD/MM/YYYY"),moment(final).format("DD/MM/YYYY"),self.eventos[idx].descricao);
        }
      }
      self.dialog = true;
      //setTimeout(function() {
      //  self.tooltip.visible = true;
      //},200);
    },

    clickEvento(dados) {
      var self = this;
      //console.log(dados);
      self.tooltipEvento.evento = self.eventos[dados.event.id];
      self.tooltipEvento.evento.dataInicioF = moment(self.tooltipEvento.evento.dataInicio).format("DD/MM/YYYY");
      self.tooltipEvento.evento.dataFinalF = moment(self.tooltipEvento.evento.dataFinal).format("DD/MM/YYYY");
      var equipes = self.tooltipEvento.evento.setores;
      //console.log("equipes",equipes);
      for(var key in equipes) {
        self.tooltipEvento.evento.pessoas = self.setores[key].pessoas;
      }
      //console.log(self.tooltipEvento.evento.pessoas);
      setTimeout(function() {
        self.tooltipEvento.visible = true;
        self.tooltipEvento.activate = false;
        self.refresh++;
      },200);
      //console.log(dados.outside);
    },

    arraySort(vet, sortKey, desc = false) {
      function compare( a, b ) {
        if ( a[sortKey] < b[sortKey] ){
          return (desc ? 1 : -1);
        }
        if ( a[sortKey] > b[sortKey] ){
          return (desc ? -1 : 1);
        }
        return 0;
      }
      vet.sort( compare );
      return vet;
    },

    atualizaListaEventos() {
      var self = this;

      if(self.selecionados != null) {
        self.showBusca();
      }

      //console.log("this.diaMostrado",this.diaMostrado);
      //console.log("this.mesMostrado",this.mesMostrado);
      //console.log("this.type",this.type);

      var events = [];

      for(var key in self.eventos) {
        if(self.areaSel.id == "todos" || (self.eventos[key].setores != undefined && self.eventos[key].setores[self.areaSel.id] != undefined)) {
          if(self.eventos[key].mes2 == this.mesMostrado) {
            var evento = clone(self.eventos[key]);
            //console.log("evento",evento);
            evento.dataInicioF = moment(evento.dataInicio).format("DD/MM/YYYY");
            evento.dataFinalF = moment(evento.dataFinal).format("DD/MM/YYYY");
            var equipes = evento.setores;
            for(var key2 in equipes) {
              evento.pessoas = self.setores[key2].pessoas;
            }
            events.push(evento);
          }
        }
      }

      var dias = [];
      for(var i=0; i < 32; i++) {
        dias.push({});
      }
      for(var key in events) {
        dias[events[key].diaInicioEvento][events[key].id] = events[key];
      }
      //console.log(dias);
      var events2 = [];
      self.eventosLista = {};
      for(var i=0; i < 32; i++) {
        var lista = [];
        for(var key in dias[i]) {
          lista.push(dias[i][key]);
        }
        if(lista.length > 0) {
          var diaTexto = lista[0].diaInicioEvento + " de " + moment(new Date(lista[0].dataInicio)).format("MMMM") + " de " + lista[0].mesano.substr(4,4);
          self.eventosLista[diaTexto] = {
            diaTexto: diaTexto
          }
          self.eventosLista[diaTexto].eventosLista = lista;
        }
      }
      //events = this.arraySort(events,"diaInicioEvento");
      //self.eventosLista = clone(events2);
      console.log("self.eventosLista",self.eventosLista);
      //self.refresh++;
      self.$forceUpdate();
    },

    showListaMes() {
      var self = this;
      self.calendarStyle = "position: absolute; left: -1500px";
      this.listaMes = true;
      self.refresh++;
      this.atualizaListaEventos();
    },

    showBusca() {
      var self = this;
      self.calendarStyle = "position: absolute; left: -1500px";
      this.listaMes = true;

      //console.log("this.selecionados",this.selecionados);

      var events = [];

      for(var key in self.eventos) {
        if(self.areaSel.id == "todos" || self.eventos[key].setores[self.areaSel.id] != undefined) {
          if(self.eventos[key].descricao == this.selecionados) {
            var evento = clone(self.eventos[key]);
            //console.log("evento",evento);
            evento.dataInicioF = moment(evento.dataInicio).format("DD/MM/YYYY");
            evento.dataFinalF = moment(evento.dataFinal).format("DD/MM/YYYY");
            var equipes = evento.setores;
            for(var key2 in equipes) {
              evento.pessoas = self.setores[key2].pessoas;
            }
            events.push(evento);
          }
        }
      }

      var dias = [];
      for(var i=0; i < 32; i++) {
        dias.push({});
      }
      for(var key in events) {
        dias[events[key].diaInicioEvento][events[key].id] = events[key];
      }
      //console.log(dias);
      var events2 = [];
      self.eventosLista = {};
      for(var i=0; i < 32; i++) {
        var lista = [];
        for(var key in dias[i]) {
          lista.push(dias[i][key]);
        }
        if(lista.length > 0) {
          var diaTexto = lista[0].diaInicioEvento + " de " + moment(new Date(lista[0].dataInicio)).format("MMMM") + " de " + lista[0].ano;
          self.eventosLista[diaTexto] = {
            diaTexto: diaTexto
          }
          self.eventosLista[diaTexto].eventosLista = lista;
        }
      }
      //events = this.arraySort(events,"diaInicioEvento");
      //self.eventosLista = clone(events2);
      //console.log("self.eventosLista",self.eventosLista);
      //self.refresh++;
      //self.$forceUpdate();

    },


  }
}
</script>

<style scoped>

.v-tooltip__content {
  opacity: 1 !important;
}

</style>
